import Component from '../core/Component'
import Highcharts from 'highcharts'
import { format } from '../utils/Number'

const defaults = {
    format: 'normal',
    tickInterval: 2,
    min: null,
    max: null,
    categories: [],
    series: []
}

export default class ChartBar extends Component {
    constructor(element) {
        super(element, defaults)
    }

    prepare() {
        const isPercentage = this.options.format === 'percentage'
        const options = {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                style: {
                    color: '#00319E',
                    fontFamily: '"Univers LT CYR 45", sans-serif'
                },
                borderColor: '#00319E',
                events: {
                    load() {
                        const chart = this
                        chart.series.forEach(row => {
                            row.points.forEach(point => {
                                point.dataLabel.attr({
                                    y: chart.plotHeight
                                })
                            })
                        })
                    },
                    resize() {
                        const chart = this
                        chart.series.forEach(row => {
                            row.points.forEach(point => {
                                point.dataLabel.attr({
                                    y: chart.plotHeight
                                })
                            })
                        })
                    }
                }
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            colors: ['#9257FD'],
            title: false,
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        verticalAlign: 'bottom',
                        enabled: true,
                        color: '#00319E',
                        formatter: function() {
                            return format(this.y, 0) + (isPercentage ? ' %' : '')
                        },
                        style: {
                            textOutline: 'none'
                        }
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            xAxis: {
                categories: this.options.categories,
                labels: {
                    style: {
                        color: '#00319E'
                    },
                    y: 35
                },
                lineColor: '#00319E',
                lineWidth: 2,
                tickColor: '#00319E',
                tickWidth: 2,
                tickInterval: 1,
                tickmarkPlacement: 'between',
                type: 'category'
            },
            yAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    enabled: false
                },
                gridLineDashStyle: 'dot',
                gridLineWidth: 2,
                gridLineColor: '#00319E',
                tickInterval: this.options.tickInterval
            },
            series: this.options.series
        }

        if (this.options.min) {
            options.yAxis.min = this.options.min
        }

        if (this.options.max) {
            options.yAxis.max = this.options.max
        }

        this.chart = Highcharts.chart(this.element, options)
    }

}