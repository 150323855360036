import Component from '../core/Component'
import Highcharts from 'highcharts'
import { format } from '../utils/Number'

export default class ChartBar extends Component {

    prepare() {
        const isPercentage = this.options.format === 'percentage'
        const options = {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                style: {
                    color: '#00319E',
                    fontFamily: '"Univers LT CYR 45", sans-serif'
                },
                borderColor: '#00319E',
                events: {
                    load() {
                        const chart = this
                        chart.series.forEach(row => {
                            row.points.forEach(point => {
                                point.dataLabel.attr({
                                    y: chart.plotHeight
                                })
                            })
                        })
                    },
                    resize() {
                        const chart = this
                        chart.series.forEach(row => {
                            row.points.forEach(point => {
                                point.dataLabel.attr({
                                    y: chart.plotHeight
                                })
                            })
                        })
                    }
                }
            },
            credits: {
                enabled: false
            },
            legend: {
                itemStyle: {
                    color: '#00319E'
                }
            },
            colors: ['#D0B6FE', '#9257FD', '#6410FC'],
            title: false,
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        verticalAlign: 'bottom',
                        enabled: true,
                        color: '#00319E',
                        formatter: function() {
                            return Highcharts.numberFormat(this.y, 1) + (isPercentage ? ' %' : '')
                        },
                        style: {
                            textOutline: 'none'
                        }
                    }
                }
            },
            xAxis: {
                categories: this.options.categories,
                labels: {
                    style: {
                        color: '#00319E'
                    },
                    y: 35
                },
                lineColor: '#00319E',
                lineWidth: 1,
                tickColor: '#00319E',
                tickWidth: 1,
                tickInterval: 1,
                tickmarkPlacement: 'between',
                type: 'category'
            },
            yAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    style: {
                        color: '#00319E'
                    },
                    formatter: function() {
                        if (this.isFirst) {
                            return ''
                        }

                        return isPercentage ? `${this.value} %` : this.value
                    }
                },
                allowDecimals: false,
                gridLineDashStyle: 'dot',
                gridLineWidth: 1,
                gridLineColor: '#00319E'
            },
            series: this.options.series
        }

        if (this.options.min) {
            options.yAxis.min = this.options.min
        }

        if (this.options.max) {
            options.yAxis.max = this.options.max
        }

        this.chart = Highcharts.chart(this.element, options)
    }

}